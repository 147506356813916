import type { SetterOrUpdater } from 'recoil';
import { atom } from 'recoil';

import type { TeamMember } from '@/api/v4/team.api';
import type { ExampleCompany } from '@/features/explore/explore.state';
import type { UserRoleOption } from '@/features/settings/settings.state';
import type { FileDelete } from '@/helpers/files';
import type { VerificationMethod } from '@/user/verification-method/verification-method.type';

interface Set2FAMethodModal {
  state: 'set2FAMethod';
  method: VerificationMethod;
}

interface ChangePasswordModal {
  state: 'changePassword';
}

interface AddCompanyModal {
  state: 'addCompany';
  projectId: string;
  onSuccess?: () => void;
}

interface AddCompanyToProjectsModal {
  state: 'addCompanyToProjects';
}

interface CreateNewCompanyModal {
  state: 'createNewCompany';
  closeOnCreate?: boolean;
  defaultSearchPhrase?: string;
  defaultWebsite?: string;
  navigateToPageOnCreated?: boolean;
  onClose?: (createdCompanies: string[]) => Promise<void>;
}

interface DeleteProjectListingModal {
  state: 'deleteProjectListing';
  listingId: string;
}

interface DeleteProjectModal {
  state: 'deleteProject';
  projectId: string;
}

interface DeleteTeamModal {
  state: 'deleteTeam';
  teamId: string;
  teamName: string;
}

interface DeleteTeamMemberModal {
  state: 'deleteTeamMember';
  user: TeamMember;
  onConfirm: () => void;
}

interface DuplicateProjectModal {
  state: 'duplicateProject';
}

interface ShareProjectModal {
  state: 'shareProject';
}

interface DealModal {
  state: 'deal';
  listingId: string;
  onClosed?: () => void;
  onSuccess?: (newStatusId: string) => void;
}

interface ShareCompanyModal {
  state: 'shareCompany';
  companyId: string;
}

interface RevokeInvitationModal {
  state: 'revokeInvitation';
  userId: number;
}

interface DeleteCompanyModal {
  state: 'deleteCompany';
  company: {
    id: string;
    name: string;
  };
}

interface DeleteCompaniesModal {
  state: 'deleteCompanies';
}

export type ITagsModalType = 'project' | 'team' | 'company';

interface ManageTagsModal {
  state: 'manageTags';
  type: ITagsModalType;
  entityId: string;
  refetchTags?: () => void;
}

interface DeleteTeamFileModal {
  state: 'deleteTeamFile';
  teamId: string;
  file: FileDelete;
}

interface DeleteProjectFileModal {
  state: 'deleteProjectFile';
  projectId: string;
  file: FileDelete;
}

interface ShareTeamFileModal {
  state: 'shareTeamFile';
  teamId: string;
}

interface DeleteCompanyFileModal {
  state: 'deleteCompanyFile';
  companyId: string;
  file: FileDelete;
}

interface ShareCompanyFileModal {
  state: 'shareCompanyFile';
  companyId: string;
}

interface InviteUser {
  state: 'inviteUser';
}

interface AddTeamMember {
  state: 'addTeamMember';
}

interface VerifyCompanyModal {
  state: 'verifyCompany';
  errorFields: string[];
}

interface ConfirmDeleteModal {
  state: 'confirmDelete';
  onConfirm: () => void;
}

interface ConfirmDiscardMeetingFormChangesModal {
  state: 'discardMeetingFormChanges';
  mainAction: () => void;
}

interface ConfirmDiscardChangesModal {
  state: 'confirmDiscardChanges';
  mainAction: () => void;
}
interface ChangeUserRoleModal {
  state: 'changeUserRole';
  userId: number;
  userDisplayName: string;
  currentRole: UserRoleOption;
  newRole: UserRoleOption;
  hasLinkedEntities: boolean;
}

interface LoadCompaniesToProjectModal {
  state: 'loadCompaniesToProject';
  projectId: string;
}

interface SharedAssetsModal {
  state: 'showSharedAssets';
  email: string;
  userDisplayName: string;
}

interface SearchModal {
  state: 'search';
}

interface EnhanceProjectFormModal {
  state: 'enhanceProjectForm';
}

interface EnhanceProjectIntroModal {
  state: 'enhanceProjectIntro';
}

interface AddExampleCompaniesToExploreFormModal {
  state: 'addExampleCompaniesToExploreForm';
  exampleCompanies: ExampleCompany[];
  setExampleCompanies: SetterOrUpdater<ExampleCompany[]>;
}

interface askXModal {
  state: 'askXModal';
  showResult?: boolean;
}

type Modal =
  | Set2FAMethodModal
  | ChangePasswordModal
  | AddCompanyModal
  | AddCompanyToProjectsModal
  | AddTeamMember
  | CreateNewCompanyModal
  | DeleteProjectListingModal
  | DeleteProjectModal
  | LoadCompaniesToProjectModal
  | DeleteTeamModal
  | DeleteTeamMemberModal
  | DuplicateProjectModal
  | ShareProjectModal
  | ShareCompanyModal
  | DealModal
  | ManageTagsModal
  | DeleteCompanyModal
  | DeleteCompaniesModal
  | DeleteTeamFileModal
  | DeleteProjectFileModal
  | ShareTeamFileModal
  | DeleteCompanyFileModal
  | ShareCompanyFileModal
  | InviteUser
  | VerifyCompanyModal
  | ConfirmDeleteModal
  | ConfirmDiscardMeetingFormChangesModal
  | ChangeUserRoleModal
  | SharedAssetsModal
  | SearchModal
  | EnhanceProjectIntroModal
  | EnhanceProjectFormModal
  | RevokeInvitationModal
  | AddExampleCompaniesToExploreFormModal
  | ConfirmDiscardChangesModal
  | askXModal
  | null;

export const modalState = atom<Modal>({
  default: null,
  key: 'modalState',
});
