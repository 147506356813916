import type { Row } from '@/ui/table/table.types';

import type { SkeletonRowConfig } from './skeleton-row';
import { SkeletonRow } from './skeleton-row';
import { TableRow } from './table-row';

export const INFINITE_TABLE_ROW_HEIGHT = 76;

export interface TableSkeletonConfig extends SkeletonRowConfig {
  numberOfRows?: number;
}

interface Props {
  id?: string;
  rows: Row[];
  skeletonConfig?: TableSkeletonConfig;
  isLoading?: boolean;
  borderColor?: string;
  rowCursor?: string;
}

export const TableBody = ({
  id,
  rows,
  isLoading,
  skeletonConfig,
  borderColor,
  rowCursor,
}: Props) => {
  const generateSkeletonRows = (skeletonConfig: TableSkeletonConfig) => {
    if (skeletonConfig.numberOfRows) {
      return (
        <>
          {Array.from({ length: skeletonConfig.numberOfRows }).map(
            (_, index) => (
              <SkeletonRow
                key={index}
                {...skeletonConfig}
                withRandomFirstColumnWidth
              />
            ),
          )}
        </>
      );
    }

    return <SkeletonRow {...skeletonConfig} />;
  };

  return (
    <tbody id={id} data-testid={id}>
      {isLoading && skeletonConfig
        ? generateSkeletonRows(skeletonConfig)
        : rows.map(row => (
            <TableRow
              id={id ? `${id}-row-${row.id}` : undefined}
              key={row.id}
              row={row}
              borderColor={borderColor}
              bgColor={row.rowBackgroundColor}
              rowCursor={rowCursor}
            />
          ))}
    </tbody>
  );
};
