import { styled } from 'goober';

import type { IAlign, RowPadding } from '../table.types';

import { Td } from './table-row';
import { getRandomNumberValue } from '@/helpers/get-random-number-value';
import { forwardRef } from 'react';

export interface SkeletonRowConfig {
  columns: number;
  rowPadding?: RowPadding;
  rowConfig?: {
    align?: IAlign;
  }[];
}

interface SkeletonRowProps extends SkeletonRowConfig {
  withRandomFirstColumnWidth?: boolean;
}

export const SkeletonRow = ({
  columns,
  rowConfig,
  rowPadding,
  withRandomFirstColumnWidth: withRandomWidth,
}: SkeletonRowProps) => {
  return (
    <Tr>
      {Array.from({ length: columns }).map((_, cellIndex) => (
        <Td key={cellIndex} rowPadding={rowPadding}>
          <SkeletonText
            align={rowConfig?.[cellIndex]?.align}
            withRandomWidth={withRandomWidth && cellIndex === 0}
          />
        </Td>
      ))}
    </Tr>
  );
};

const Tr = styled('tr')`
  height: 76px;
`;

export const SkeletonText = styled('div', forwardRef)<{
  align?: IAlign;
  withRandomWidth?: boolean;
}>`
  height: 16px;
  border-radius: 4px;
  width: ${({ withRandomWidth }) =>
    withRandomWidth ? getRandomNumberValue(20, 80) : 50}%;
  ${({ theme }) => theme.animations.skeletonAnimation()};
  ${({ align }) =>
    align === 'right'
      ? 'margin-left: auto;'
      : align === 'center'
      ? 'margin: 0 auto;'
      : ''}
`;
