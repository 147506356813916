import { styled } from 'goober';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SEARCH_INPUT_MAX_CHAR } from '@/constants';
import { useFocusOnMount } from '@/hooks/use-focus-on-mount';
import { colors } from '@/theme/colors';
import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';

import { useDebounce } from 'use-debounce';
import { SearchContent } from './search-content';

export const Search = () => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });

  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [debouncedPhrase] = useDebounce(searchPhrase, 300, {
    maxWait: 1000,
    leading: true,
  });
  const searchRef = useRef<HTMLInputElement>(null);
  useFocusOnMount(searchRef);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setSearchPhrase(null);
    }

    if (e.target.value) {
      setSearchPhrase(e.target.value);
    }
  };

  return (
    <SearchContainer>
      <Input
        name="search"
        ref={searchRef}
        leftIcon="Search"
        iconSize="26px"
        iconColor={colors.gray.c7}
        padding="3px 8px"
        fontSize="12px"
        gap="8px"
        placeholder={t`searchPlaceholder`}
        onInput={handleChange}
        maxLength={SEARCH_INPUT_MAX_CHAR}
      />
      <SearchContent
        immediateSearchPhrase={searchPhrase}
        debouncedSearchPhrase={debouncedPhrase}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled(Stack)`
  height: 100%;
  flex: 1;
`;
