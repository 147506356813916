import { styled } from 'goober';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal as ModalComponent } from '@/ui/modal/modal';

import { Search } from '../search/global-search/search';
import { searchResultsFilterState } from '../search/global-search/search.state';

export const SearchModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const resetFilters = useResetRecoilState(searchResultsFilterState);

  if (modal?.state !== 'search') {
    return null;
  }

  const onCloseModal = () => {
    resetFilters();
    setModal(null);
  };

  return (
    <Modal closeIcon={false} onClose={onCloseModal}>
      <Search />
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  min-height: 400px;
  max-height: 600px;
  flex-direction: row;
  max-width: 688px;
`;
